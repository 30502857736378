<template>
  <div>
    <vue-breadcrumbs :breadCrumbs='breadCrumbs'/>
    <bus-model ref="busModel" :selectable='true' :isSleeping="true" :limit='limit'/>
    <v-btn @click="submitHandler" color="primary" width="160" height="48" class="mt-6">Выбрать</v-btn>
  </div>
</template>

<script>
import VueBreadcrumbs from '@/components/ui/vueBreadcrumbs.vue'
import BusModel from '@/components/buses/busModel.vue'
import {mapMutations} from 'vuex'
import { toursService } from '@/services/tours.js'
export default {
    components: {
        VueBreadcrumbs,
        BusModel
    },
    data: () => ({
    }),
    created() {
        this.getBus()
    },
    computed: {
        limit() {
            return Number(this.$route.query.adult) + Number(this.$route.query.child) + Number(this.$route.query.pensioner)
        },
        breadCrumbs() {
            let query = this.$route.query
            delete query.bus
            return [
                {
                    text: 'Бронирование туров',
                    to: `/booking/tours/`,
                    query
                },
                {
                    text: 'Выбор места',
                    to: '/booking/tours',
                }
            ]
        }
    },
    methods: {
        ...mapMutations({
            setSleepingModel: 'busModel/SET_SLEEPING_MODEL',
            translateFromBack: 'busModel/TRANSFORM_BUS_MODEL_FROM_BACK'
        }),
        async submitHandler() {
            const seats = this.$refs.busModel.getClickedSeats()
            if (seats.length != this.limit) {
                this.$store.commit('ui/SET_NOTIFICATION', {show: true, message: `Выберите ещё ${this.limit-seats.length} места`, color: 'error'})
                return
            }
            const data = {
                id: this.$route.params.id,
                data: {seat_places: seats.map(el => el.id)}
            }
            await toursService.bookSeatPlaces(data)
            let query = {
                seats: seats.map(el => el.id).join(','),
                ...this.$route.query,
            }
            this.$router.push({
                path: `/booking/tours/${this.$route.params.id}/card/`,
                query
            })
        },
        async getBus() {
            try {
                this.$loading(true)
                const res = await toursService.activeTourBuses(this.$route.params.id)
                this.translateFromBack(res.bus)
            } catch (e) {
                console.error(e);
            } finally {
                this.$loading(false)
            }
        }
    }
}
</script>

<style lang="scss" scoped>

</style>